import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import Blog from "../components/blog-card";
import ContactForm from "../components/Contactformulier";
import { HTMLContent } from "../components/Content";
import Layout from "../components/layout";
import SEO from "../components/seo";

export const BlogsPageTemplate = ({ post, blogs }) => {
  return (
    <>
      <section className="max-w-2xl mx-auto mb-12">
        <HTMLContent
          className="prose text-lg mb-2 font-light  netlify-html text-center "
          content={post.html}
        />
      </section>
      <section className="grid grid-cols-1 gap-4 mx-auto max-w-5xl grid-flow-row  md:grid-cols-2 lg:grid-cols-3 xl:mb-16  ">
        {blogs.edges.map((edge) => {
          let blogObj = edge.node;
          return (
            <Blog
              key={blogObj.fields.slug}
              title={blogObj.frontmatter.title}
              summary={blogObj.frontmatter.summary}
              link={blogObj.fields.slug}
            />
          );
        })}
      </section>
    </>
  );
};

const BlogsPage = ({ data }) => {
  const { markdownRemark: post, allMarkdownRemark: blogs } = data;

  return (
    <>
      <SEO
        description={post.frontmatter.seo.meta_description}
        pathname={post.frontmatter.path}
        title={post.frontmatter.seo.meta_title}
      />
      <Layout
        heroBackgroundImage={post.frontmatter.hero_background_image}
        subtitle={post.frontmatter.subtitle}
        title={post.frontmatter.title}
      >
        <main className="bg-gladior-dark ">
          <section className="container mx-auto px-6 py-10  max-w-7xl  ">
            <BlogsPageTemplate content={post.html} blogs={blogs} post={post} />
          </section>
          <ContactForm />
        </main>
      </Layout>
    </>
  );
};

BlogsPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default BlogsPage;

export const blogsPageQuery = graphql`
  query BlogsPage($id: String!) {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "blog-page" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            subtitle
            title
            summary
          }
          fields {
            slug
          }
        }
      }
    }

    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        path
        hero_background_image {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        seo {
          meta_title
          meta_description
        }
        summary
      }
    }
  }
`;
