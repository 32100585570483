import { Link } from "gatsby";
import React from "react";

function truncate(input) {
  if (input.length > 300) {
    return input.substring(0, 300) + "...";
  }
  return input;
}

function BlogCard(props) {
  let { title, summary, link } = props;

  return (
    <div
      className="flex flex-col  items-center justify-center w-full"
      key={link}
    >
      <div className="flex flex-col  h-full">
        <div className="flex flex-col flex-grow">
          <h3 className="font-semibold text-2xl mb-2 font-display  text-white mb-4">
            {title}
          </h3>

          <p className="prose font-light  ">{truncate(summary)}</p>
        </div>

        <div className=" mt-4 flex-grow-0 text-white flex flex-col justify-start content-start items-start text-center mt-2 mb-8 ">
          <Link
            className="bg-transparent hover:bg-gladior-pink  text-white  font-semibold hover:text-white py-2 px-4 border border-gladior-pink hover:border-transparent rounded"
            to={link}
          >
            Lees verder
          </Link>
        </div>
      </div>
    </div>
  );
}
export default BlogCard;
